.badge {
    &.bg-danger {
        background-color: #FFAA46 !important;
    }
    &.bg-warning {
        background-color: #F6CA56 !important;
    }
    &.bg-primary {
        background-color: #A7DE61 !important;
    }
    &.bg-success {
        background-color: #65C775 !important;
    }
}