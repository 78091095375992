.form-label::first-letter {
	text-transform: capitalize;
}

.survey-header,
.session {
	background-color: #fff;
	border-radius: 20px;
	padding: 40px 60px;
	position: relative;
}

.survey-header {
	margin: 0px auto 0;
	padding: 20px 40px;
	display: flex;
	align-items: start;
	justify-content: start;
	width: 100%;
	@media screen and (max-width: 768px) {
		padding: 20px;
	}
	svg {
		width: 32px;
		height: 32px;
		margin-top: -8px;
		margin-right: 8px;
		margin-left: -12px;
		&:hover {
			cursor: pointer;
		}
	}
	// animation-name: panel-enter;
	//  	animation-duration: 400ms;
	//  	animation-iteration-count: 1;
	//  	animation-fill-mode: forwards;
	//  	opacity: 0;
	h1 {
		font-size: 16px;
		font-weight: 400;
		margin-bottom: 0;
		margin-top: 8px;
		text-align: right;
	}
}

.session {
	margin: 0 auto 0;
	@media screen and (max-width: 768px) {
		padding: 0;
		img {
			width: 100% !important;
			height: auto !important;
		}
	}

}

.question,
.form-actions {
	padding: 20px 0;
	.btn {
		padding: 10px 20px;
		font-size: 20px;
	}
	// animation-name: panel-enter;
	//  	animation-duration: 300ms;
	//  	animation-iteration-count: 1;
	//  	animation-fill-mode: forwards;
	//  	animation-timing-function: ease-out;
	//  	opacity: 0;
}

.choice {
	// animation-name: choice-enter;
	//  	animation-duration: 300ms;
	//  	animation-iteration-count: 1;
	//  	animation-fill-mode: forwards;
	//  	animation-timing-function: ease-out;
	//  	opacity: 0;
}

// @for $i from 0 through 5 {
// 	.question_#{$i} {
// 		$qDelay: ($i+1) * 200;
// 		animation-delay: #{$qDelay}ms;
// 		@for $n from 0 through 9 {
// 			.choice_#{$n} {
// 				animation-delay: #{$qDelay + $n * 100}ms;
// 			}
// 		}
// 	}
// }

.session-container {
	// animation-name: session-enter;
	//  	animation-duration: 300ms;
	//  	animation-delay: 1s;
	//  	animation-iteration-count: 1;
	//  	animation-fill-mode: forwards;
	//  	animation-timing-function: ease-out;
	//  	min-height: 2000px;
}

#session-form {
	padding-bottom: 80px;

}

.form-actions {
	background: #fff;
	position: fixed;
	bottom: 0px;
	left: 0;
	width: 100%;
	padding: 28px 40px 28px;
	border-top: 2px solid #ddd;
	@media screen and (max-width: 768px) {
		padding: 20px 0;
	}
}

.survey-complete {
	text-align: center;
	h2 {
        font-size: 48px;
        text-align: center;
    }
}