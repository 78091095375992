
@mixin gameboard-theme($themeColor, $themeShadow) {
    #gameboard-header {
        background: $themeColor;
    }

    .gameboard-icon {
        background-color: #ddd;
        box-shadow: 0 8px 0px #b1b1b1;
        &:hover {
            cursor: pointer;
            transform: translateY(2px);
            box-shadow: 0 6px 0px #b1b1b1;
        }

        &:active {
            box-shadow: none;
            transform: translateY(8px);
            transition: 0ms ease all;
        }


        &.complete {
            background-color: $themeColor;
            box-shadow: 0 8px 0px $themeShadow;

            &:hover {
                cursor: pointer;
                transform: translateY(2px);
                box-shadow: 0 6px 0px $themeShadow;
            }

            &:active {
                box-shadow: none;
                transform: translateY(8px);
                transition: 0ms ease all;
            }
        }
    }
}

#gameboard {

    .plant {
        position: absolute;
        top: 250px;
        right: 90px;

        @media screen and (max-width: 1199px) {
            top: 250px;
            right: 50px;
        }
    }

    .sun {
        width: 90px;
        position: absolute;
        top: 560px;
        left: 120px;

        @media screen and (max-width: 1199px) {
            top: 560px;
            left: 50px;
        }
    }

    .ring {
        position: absolute;
        top: -10px;
        left: -11px;
        width: 92px;
        height: 88px;
        border-radius: 50%;
        border: 5px solid #ddd;
        z-index: 0;
    }

    @include gameboard-theme(#dddddd, #b1b1b1);

    &.blue {
        $themeColor: #2684FF;
        $themeShadow: #1F6FD9;
        @include gameboard-theme($themeColor, $themeShadow);
    }

    &.red {
        $themeColor: #F94E4E;
        $themeShadow: #BD3232;
        @include gameboard-theme($themeColor, $themeShadow);
    }

    &.cyan {
        $themeColor: #64CFF1;
        $themeShadow: #41A3C2;
        @include gameboard-theme($themeColor, $themeShadow);
    }

    &.pink {
        $themeColor: #FF6B9D;
        $themeShadow: #D94D7A;
        @include gameboard-theme($themeColor, $themeShadow);
    }

    &.yellow {
        $themeColor: #FFD166;
        $themeShadow: #D9B14D;
        @include gameboard-theme($themeColor, $themeShadow);
    }

    &.magenta {
        $themeColor: #FF6B9D;
        $themeShadow: #D94D7A;
        @include gameboard-theme($themeColor, $themeShadow);
    }

    &.green {
        $themeColor: #27AE60;
        $themeShadow: #1F8C4D;
        @include gameboard-theme($themeColor, $themeShadow);
    }

    &.light-green {
        $themeColor: #2ECC71;
        $themeShadow: #24A963;
        @include gameboard-theme($themeColor, $themeShadow);
    }

    &.orange {
        $themeColor: #FF9F43;
        $themeShadow: #D97D2E;
        @include gameboard-theme($themeColor, $themeShadow);
    }

    &.purple {
        $themeColor: #9B51E0;
        $themeShadow: #7A3DBD;
        @include gameboard-theme($themeColor, $themeShadow);
    }
}



#gameboard-header {
    background: #ddd;
    padding: 20px;
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    border-radius: 12px;
    margin-bottom: 40px;

    h1 {
        color: #fff;
        margin: 0;
        font-size: 28px;
    }
}

#you-are-here {
    z-index: 99;
}

.gameboard-modal {
    padding: 72px;
    @media screen and (max-width: 768px) {
        padding: 72px 32px;
    }

    h3 {
        margin-bottom: 2px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .credits {
            font-size: 16px;
            font-weight: 400;
        }
    }

    h4 {
        font-weight: 400;
        color: #aaa;
        font-size: 16px;
        margin-bottom: 12px;
    }
}

.gameboard-steps {
    margin: 20px 0;
}

.gameboard-step-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;

    $x: 110px;

    &:nth-child(1) {
        .gameboard-step {
            margin-left: 0px;
            margin-bottom: -0.6rem !important;
        }
    }

    &:nth-child(2) {
        .gameboard-step {
            //margin-left: -80px;
            margin-left: $x * -1;
        }
    }

    &:nth-child(3) {
        .gameboard-step {
            //margin-left: -120px;
            margin-left: $x * -1.5;
        }
    }

    &:nth-child(4) {
        .gameboard-step {
            //margin-left: -80px;
            margin-left: $x * -1;
            margin-bottom: -0.5rem !important;
        }
    }

    &:nth-child(5) {
        .gameboard-step {
            margin-left: 0px;
            margin-bottom: -0.5rem !important;
        }
    }

    &:nth-child(6) {
        .gameboard-step {
            //margin-left: 80px;
            margin-left: $x * 1;
        }
    }

    &:nth-child(7) {
        .gameboard-step {
            //margin-left: 120px;
            margin-left: $x * 1.5;
        }
    }

    &:nth-child(8) {
        .gameboard-step {
            //margin-left: 80px;
            margin-left: $x * 1;
        }
    }
}

.gameboard-step {
    position: relative;

}

.popover-body {
    font-size: 16px;
}

.gameboard-icon {
    border: none;
    box-shadow: 0 8px 0px #b1b1b1;
    z-index: 2;
    position: relative;
    width: 70px;
    height: 60px;
    border-radius: 50%;

    &:hover {
        cursor: pointer;
        transform: translateY(2px);
        box-shadow: 0 6px 0px #b1b1b1;
    }

    &:active {
        box-shadow: none;
        transform: translateY(8px);
        transition: 0ms ease all;
    }

    &.trophy-icon {
        width: 120px;
        height: 120px;
        background-color: transparent !important;
        box-shadow: none !important;
        &:hover, &:active {
            transform: none !important;
            margin: 0 !important;
            box-sizing: none !important;
        }
    }
}

#dropdown-basic {
    border: none;
    background: #f1f1f1;
    border-radius: 8px;
    padding: 8px 16px;
    margin-bottom: 4px;

    &:focus {
        box-shadow: 0 4px 0 #ddd;
        margin-bottom: 4px;
        margin-top: 0px;
    }

    &:active {
        box-shadow: none;
        margin-bottom: 0;
        margin-top: 4px;
    }
}