.required:after {
    content: ' *';
    color: #dc3644
}

table.observation-form-table {
    th, td {
        text-align: center;
        padding: 12px !important;
        width: 100px;
        &:first-child {
            text-align: left;
            width: auto;
        };
    }
    .form-check-inline {
        margin-right: 0;
        padding: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        .form-check-input {
            margin: 0;
        }
    }
    thead {
        border-top: none !important;
    }
}
.features-table {
    th {
        color: rgba(0,0,0,0);
        &:first-child {
            color: rgba(0,0,0,1);
        }
    }
}
.strategy-input {
    input[type="checkbox"] {
        margin-right: 12px;
        margin-top: 1px;
    }
}
.strategy-name {
    font-weight: 600;
}