.progress-container {
    width: 100%;
    flex-grow: 1 !important;
}
.progress-bar-container {
    position: relative;
    margin-top: 20px;

    svg {
        width: 36px;
        height: 36px;
        position: absolute;
        left: -40px;
        top: -10px;
        fill: #aaa;

        &:hover {
            cursor: pointer;
        }
    }
}

.progress-bar {
    background-color: #38AD4B;
}

.progress {
    width: 100%;
}