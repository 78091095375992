.surveys-in-progress {
	.survey-card {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: #fff;
		border-radius: 8px;
		padding: 2rem 2rem;
		box-shadow: 0 2px 6px rgb(0 0 0 / 12%);
		margin-bottom: 12px;
		.actions {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			.btn {
				margin-right: 0;
				margin-left: 8px;
			}
		}
		h4 {
			font-size: 18px;
			font-weight: 400;
			margin: 0;
		}
		p {
			margin: 0;
			font-size: 14px;
		}
		.icon-container {
			margin-right: 20px;
			background: #4264e6;
			svg {
				width: 30px;
				height: 30px;
				color: #fff;
			}
		}
	}
}