@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');

@import './scss/colors';
@import './scss/layout';
@import './scss/navigation';
@import './scss/animation';
@import './scss/panel';
@import './scss/button';
@import './scss/modal';
@import './scss/form';
@import './scss/dashboard';
@import './scss/results';
@import './scss/strategy-table';
@import './scss/survey';
@import './scss/goals';
@import './scss/goal-wizard';
@import './scss/achievements';
@import './scss/reminders';
@import './scss/surveys-in-progress';
@import './scss/loader';
@import './scss/icons';
@import './scss/reflections';
@import './scss/table';
@import './scss/agree-to-terms';
@import './scss/user';
@import './scss/gameboard';
@import './scss/onboarding';
@import './scss/progress-bar';
@import './scss/profile';
@import './scss/strategies';
@import './scss/badges';

html {
	//background-color: #dbecd1;
	
}

body {
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
}

h1 {
	font-size: 38px;
	font-weight: 700;
	margin-bottom: 20px;
}

h2 {
	font-size: 32px;
	font-weight: 700;
	margin-bottom: 16px;
	strong {
		font-weight: inherit;
	}
}

h3 {
	font-size: 22px;
	font-weight: 700;
	//background: #f1f1f5;
	//padding: 20px;
	//border-radius: 12px;
	margin-bottom: 8px;
	line-height: 1.4;
}
h4 {
	font-size: 18px;
	font-weight: 700;
}

p {
	font-size: 16px;
	font-weight: 400;
}

.debug {
	font-size: 12px;
	color: red;
	position: absolute;
	top: 20px;
	right: 20px;
}

.placeholder {
	border-radius: 8px;
}


.survey {
	position: relative;
}

.App {
	//background-color: #dbecd1;
	padding-bottom: 60px;
}

#logo {
	width:  200px;
}

.login-form {
	input {
		padding: 12px;
	}
}

.dropdown-menu {
	a {
		font-size: 16px;
		color: #212529;
		&:hover {
			color: #4264E6;
		}
	}
}
.pagination {
	justify-content: center;
}

.card {
    margin: 0 0 12px;
	border: none;
}

#footer {
	display: none;
    //display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 4px 0;
    color: #777;
    background: #f1f1f1;
    font-size: 14px;
	z-index: 9999;
}

#settings-page {
	#password-form {
		.form-group {
			padding-right: 20px;
		}
	}
}

.password-rules {
	.rule-text {
		color: #777;
	}
	.success {
		color: #427A5F;
	}
}


.meetings-container {
	h2 {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.btn-secondary {
			background: #fff;
			margin-right: 0;
			box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
			&:focus {
				color: $nearBlack;
			}
		}
	}
	td {
		vertical-align: middle;
		padding: 12px 4px;
		&:first-child {
			min-width: 120px;
		}
	}
	
}

.meeting-form {
	.checkbox-group {
		columns: 3;
		.form-check {
			width: 100%;
		}
	}
	.btn {
		width: auto;
		margin-right: 0;
	}
}
.observation-form {
	.checkbox-group {
		columns: 2;
		.form-check {
			width: 100%;
		}
	}
	.btn {
		width: auto;
		margin-right: 0;
	}
}

.modal-70w {
	width: 70%;
	max-width: 900px;
}