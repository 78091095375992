.panel {
	background-color: #fff;
	border-radius: 12px;
	padding: 40px 60px;
	position: relative;
}
.panel-header {
	.btn {
		position: absolute;
		top: 44px;
		right: 52px;
	}
}