// .loader-container {
//   display: flex;
//   justify-content: center;
//   height: 40vh;
//   align-items: center;
//   &.panel-loader {
//     height: 200px;
//   }
// }
// .lds-grid {
//   display: inline-block;
//   position: relative;
//   width: 80px;
//   height: 80px;
// }
// .lds-grid div {
//   position: absolute;
//   width: 16px;
//   height: 16px;
//   border-radius: 50%;
//   background: #427A5F;
//   animation: lds-grid 1.2s linear infinite;
// }
// .lds-grid div:nth-child(1) {
//   top: 8px;
//   left: 8px;
//   animation-delay: 0s;
// }
// .lds-grid div:nth-child(2) {
//   top: 8px;
//   left: 32px;
//   animation-delay: -0.4s;
// }
// .lds-grid div:nth-child(3) {
//   top: 8px;
//   left: 56px;
//   animation-delay: -0.8s;
// }
// .lds-grid div:nth-child(4) {
//   top: 32px;
//   left: 8px;
//   animation-delay: -0.4s;
// }
// .lds-grid div:nth-child(5) {
//   top: 32px;
//   left: 32px;
//   animation-delay: -0.8s;
// }
// .lds-grid div:nth-child(6) {
//   top: 32px;
//   left: 56px;
//   animation-delay: -1.2s;
// }
// .lds-grid div:nth-child(7) {
//   top: 56px;
//   left: 8px;
//   animation-delay: -0.8s;
// }
// .lds-grid div:nth-child(8) {
//   top: 56px;
//   left: 32px;
//   animation-delay: -1.2s;
// }
// .lds-grid div:nth-child(9) {
//   top: 56px;
//   left: 56px;
//   animation-delay: -1.6s;
// }
// @keyframes lds-grid {
//   0%, 100% {
//     opacity: 1;
//   }
//   50% {
//     opacity: 0.5;
//   }
// }

.placeholder {
  opacity: 0.05;
}
// /* HTML: <div class="loader"></div> */
.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 40vh;
  align-items: center;

}
.loader {
  width: 65px;
  aspect-ratio: 1;
  --g: radial-gradient(farthest-side,#fff calc(95% - 3px),#38ad4b calc(100% - 3px) 98%,#0000 101%) no-repeat;
  background: var(--g), var(--g), var(--g);
  background-size: 30px 30px;
  animation: l10 1.5s infinite;
}
@keyframes l10 {
  0% {
    background-position: 0 0, 0 100%, 100% 100%;
  }
  25% {
    background-position: 100% 0, 0 100%, 100% 100%;
  }
  50% {
    background-position: 100% 0, 0 0, 100% 100%;
  }
  75% {
    background-position: 100% 0, 0 0, 0 100%;
  }
  100% {
    background-position: 100% 100%, 0 0, 0 100%;
  }
}