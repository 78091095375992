#main-nav {

	#logo {
		margin-top: 12px;
		margin-bottom: 32px;

		@media screen and (max-width: 992px) {
			margin-top: 4px;
		}
	}

	.nav-items {
		display: flex;
		flex-direction: column;
		justify-content: start;
		align-items: start;
		width: 100%;

		.btn {
			box-shadow: none;
			text-align: left;
			border: 2px solid #fff;
			width: 100%;
			padding: 12px 16px;
			border-radius: 12px;
			margin-bottom: 0;
			// text-transform: uppercase;
			// font-size: 16px;
			// font-weight: 600;
			// letter-spacing: 1px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			&.active,
			&.active:hover {
				background: #E4F0FF;
				color: #2684FF;
				//border: 2px solid #2684FF;
			}


			&:hover {
				border: 2px solid #fff;
				color: #888;
				background: #f1f1f1;
			}

			// cancel out default btn behavior
			&:active {
				margin-bottom: 0px;
				//margin-top: 0;
				transform: translateY(0px);
			}
		}

		a {
			color: #888;
			font-weight: 700;
			font-size: 18px;
			text-align: left;
			@media screen and (max-width: 768px) {
				font-size: 20px;
			}
			&:focus {
				box-shadow: none;
			}

			svg {
				width: 12px;
				height: 12px;
				margin-left: 6px;
				margin-top: -4px;
			}
		}

		.reminder-nav,
		.account-nav {

			&:hover,
			&:focus {
				background-color: #fff;
				color: #427A5F;
			}
		}

		.reminder-nav {
			width: 40px;
			height: auto;

			background: transparent;
			position: relative;
			border: none;

			.badge {
				font-size: 10px;
				border-radius: 20px;
				position: absolute;
				right: 0;
				top: 0;
			}
		}

		.account-nav {
			background: transparent;
			position: relative;
			border: none;
			font-weight: 600;
			display: flex;
			align-items: center;

			span {
				margin-right: 8px;
			}
		}
	}

	.reminders-dropdown {
		position: absolute;
		top: 50px;
		right: 0;
		background-color: #ffffff;
		z-index: 99;
		width: 400px;
		border-radius: 12px;
		box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
	}

	.account-dropdown {
		position: absolute;
		top: 50px;
		right: 0;
		background-color: #ffffff;
		z-index: 99;
		min-width: 160px;
		display: flex;
		flex-direction: column;
		align-items: start;
		border-radius: 12px;
		box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);

		a {
			padding: 16px;
			width: 100%;
			color: $nearBlack;
			text-decoration: none;
			font-weight: 400;
			flex-grow: 4;
			text-align: left;
			border-radius: 0;
			border-bottom: 1px solid #ddd;

			&:first-child {
				border-top-left-radius: 12px;
				border-top-right-radius: 12px;
			}

			&:last-child {
				border-bottom: none;
				border-radius: 12px;
			}

			&:hover {
				color: #4264E6;
			}
		}
	}
}

#mobile-header {
	display: none;

	@media screen and (max-width: 992px) {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		padding: 20px;
		border-bottom: 2px solid #ddd;

		svg.bi-list {
			position: absolute;
			top: 16px;
			left: 20px;
			display: block;
			width: 40px;
			height: 40px;
			margin-right: 20px;
		}
	}
}

#dropdown-backdrop {
	display: block;
	position: fixed;
	z-index: 98;
	width: 100vw;
	height: 100vh;
	left: 0;
	top: 0;
	background: transparent;
}