.reflection-activities {

    .rating {
        position: absolute;
        right: 20px;
        top: 20px;
    }

    .progress-container {
        span {
            font-size: 14px;
            font-weight: 600;
            color: #aaa;
            margin-top: -2px;
        }
    }

    .progress-complete {
        font-weight: 600;
        color: #38AD4B;
    }
    .progress {
        background: #f1f1f1;
    }
    .progress-bar {
        background-color: #ddd;
    }

    .rating {
        font-size: 14px;
        font-weight: 600;

        .badge {
            font-size: 12px;
        }
    }

    .complete {
        border: 2px solid #38AD4B;
    }

    .active {
        border: 2px solid #2684FF;
        background: #E4F0FF;

        .progress {
            background-color: #fff;
        }

        .progress-container {
            span {
                font-weight: 600;
                color: #000;
            }
        }

        .progress-bar {
            background-color: #38AD4B;
        }
    }
}

#assessment-rating {
	padding: 0;
    border: 2px solid #8f70dd;

	h3 {
		padding: 8px 12px;
		background: #8f70dc;
		color: #fff;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;

		svg {
			width: 20px;
			height: 20px;
			margin-right: 8px;
			margin-top: -2px;
		}
	}

	.card-body {
		padding: 1rem 2rem 2rem;
	}

	.form-check {
		margin-right: 20px;
		cursor: pointer;
	}
}