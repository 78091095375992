.reminders {
	margin-bottom: 20px;
	.card-body {
		padding: 2rem 2rem;
	}

}
.reminder-card {
	border-bottom: 1px solid #ddd;
	&:last-child {
		border: none;
	}
	.card {
		box-shadow: none;
		margin: 0;
		padding: 12px;
		text-align: left;
		color: $nearBlack;
	}
	.star {
		background: #8f6aee;
	}
	.btn-primary {
		font-weight: 400 !important;
		&:hover {
			background-color: #2E4AB4 !important;
			border-color: #2E4AB4 !important;
			color: #fff !important;
		}
	}
}