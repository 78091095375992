.wizard-start {
	a {
		text-decoration: none;
		color: #000;
		&:hover {
			color: #0d6efd;
		}
	}
	.card {
		border: none;
		border-radius: 12px;
		margin-bottom: 16px;
		h3 {
			font-size: 16px;
			font-weight: 400;
			text-decoration: none !important;
			margin: 0;
		}
	}
	h4 {
		font-size: 18px;
		font-weight: 400;
		margin: 0;
	}
	.disabled {
		opacity: 0.5;
		pointer-events: none;
	}
	.strategy-container {
		max-height: 420px;
	}
}
.wizard-welcome-container {
	display: flex;
	justify-content: center;
	width: 100%;
}

.wizard-welcome {
	display: block;
	margin: 60px 0 20px;
	max-width: 600px;
	text-align: center;
	p {
		font-weight: 400;
	}
}
.key-features-container {
	background: #f1f1f5;
	border-radius: 8px;
	padding: 28px 20px;
	li:empty {
		display: none;
	}
	li::first-letter {
		text-transform: capitalize;
	}
}

.sample-goal-text {
	//display: none;
	background: #fff;
	border: 3px solid #4264E6;
	padding: 12px 20px;
	border-radius: 8px;
	font-weight: 400;
	width: 100%;
	margin: 12px 0;

}
.question-label {
	font-weight: 600;
}
#goal-statement, #goal-details {
	opacity: 0;
	height: 0;
	padding: 0;
	margin: 0;
	overflow: hidden;
}

// goal review survey
#my-goal-container {
	background: #fff;
	border: 1px dashed #ddd;
	padding: 12px 20px;
	border-radius: 8px;
	font-weight: 400;
	width: 100%;
	margin: 12px 0 12px 0;
}

#goal-metadata {
	display: flex;
	font-size: 14px;
	background: #deedff;
	padding: 12px 20px;
	border-radius: 8px;
	span {
		width: 50%;
	}
}