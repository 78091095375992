.strategy-container {}

.strategy-table {

	// .header {
	// 	font-weight: 600;
	// 	.col {
	// 		margin-top: 0;
	// 		text-align: center;
	// 	}
	// }
	// .col {
	// 	padding: 16px 20px;
	// 	margin: 8px;
	// 	border-radius: 8px;
	// 	background: #bddbb7;
	// }
	// .card {
	// 	border: none;
	// 	margin: 0 0 16px;
	// 	box-shadow: 0 2px 6px rgba(0,0,0,0.12);
	// 	transition: 100ms ease-out all;
	// 	&:hover {
	// 		transform: scale(1.05);
	// 		transition: 200ms ease-out all;
	// 	}
	// }


	.badge {
		color: #fff !important;
		font-weight: 400;
		font-size: 12px !important;
		margin-top: 8px;
	}
}

.strategy-card.panel {
	padding: 20px 24px !important;
	margin-bottom: 16px;

	.card {
		margin: 0;
		padding: 0;
	}

	.card-body {
		// display: flex;
		// justify-content: space-between;
		padding: 0;

	}

	h3 {
		font-size: 16px !important;
		font-weight: 400;
		line-height: 1.4 !important;
		margin: 0;

		// padding: 1rem 1rem 0.75rem;
		// margin: 0;
		// border-bottom: 1px solid #ddd;

		// display: flex;
		// justify-content: center;
		// align-items: center;
		// text-align: center;
	}

	a {
		font-size: 14px;
		color: #2684FF;
		text-decoration: none;
		text-align: center;
		width: 100%;
		padding: 4px 0;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;

		&:hover {
			text-decoration: underline;
			cursor: pointer;
		}
	}

	.star,
	.up-arrow,
	.no-change,
	.down-arrow {
		margin: 0;
		width: 24px;
		height: 24px;
		position: absolute;
		right: -36px;
		top: -28px;
		// outline: 2px solid #fff;
		// box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
		border-radius: 24px;

		.icon-container {
			width: 24px;
			height: 24px;
		}

		svg {
			width: 14px;
			height: 14px;
			margin-right: 0px;
		}
	}

	.up-arrow {
		background: #38AD4B;

		svg {
			width: 22px;
			height: 22px;
			fill: #fff;
		}
	}

	.no-change {
		background: #999;
		left: -8px;

		svg {
			width: 22px;
			height: 22px;
			fill: #fff;
		}
	}

	.down-arrow {
		background: #999;
		left: -8px;

		svg {
			width: 22px;
			height: 22px;
			fill: #fff;
		}
	}


}