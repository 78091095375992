.reflection-activities {
	//padding: 0 40px;
	@media screen and (max-width: 992px) {
		padding: 0;
	}
	h2 {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}
.reflection-card {
	position: relative;
	margin-bottom: 20px;
	border: 2px solid #ddd;
	border-radius: 12px;
	&.complete {
		//background: #f2fff4;
		border: 2px solid #38AD4B;
	}
	&.in-progress {
		border: 2px solid #2684FF;
		background-color: #E4F0FF;
	}
	.card {
		background: transparent;
		@media screen and (max-width: 992px) {
			padding: 40px 20px 40px;
			margin: 0;
		}
	}
	.card-body {
		display: flex;
		flex-direction: column;
		justify-content: start;
		align-items: start;
		background: transparent;
		h3 {
			margin-bottom: 12px;
		}
		p {
			margin-bottom: 24px;
		}
	}

	.card-actions {
		display: flex;
		align-items: center;
		width: 100%;
		.text-danger, .btn-text {
			margin-left: 20px;
		}
		@media screen and (max-width: 768px) {
			flex-direction: column;
			align-items: center;
			justify-content: start;
			.btn {
				margin-bottom: 20px;
				width: 100%;
				justify-content: center;
			}
			.btn:first-child {
				margin: 0 0 20px;
			}
			.text-danger, .btn-text {
				margin-left: 0;
			}
		}
	}

}
.reflection-placeholder {
	height: 200px;
	margin-bottom: 20px;
	opacity: 0.1;
	border-radius: 12px !important;
}
.reflection-actions {
	.btn {
		font-size: 14px;
	}
}
.status-message {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 20px;
	right: 20px;
	color: #000;
	font-weight: 600;
}
.complete {
	.status-message {
		color: #38AD4B;
	}
}