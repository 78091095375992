.App-header {
	display: flex;
}

#main-nav {
	border-right: 2px solid #ddd;
	width: 260px;
	height: 100vh;
	background: #fff;
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: start;
	padding: 20px;
	margin-bottom: 40px;
	position: fixed;
	top: 0;
	left: 0;
	.close-nav {
		display: none;
	}
	@media screen and (max-width: 992px) {
		z-index: 999;
		width: 100%;
		border: none;
		transition: 200ms ease-in all;
		&.closed {
			left: -100vw;
			transition: 100ms ease-in all;
		}
		.close-nav {
			display: block;
			position: absolute;
			top: 20px;
			right: 20px;

			svg {
				display: block;
				width: 40px;
				height: 40px;
			}


		}
	}
}

.main-container {
	padding-left: 260px;
	//max-width: 2000px !important;
	@media screen and (max-width: 992px) {
		padding-left: 12px;
		padding-top: 70px;
	}
}

.focus-layout {
	.panel {
		padding: 40px 60px 60px;
	}

	#logo {
		display: block;
		width: 300px;
		height: 36px;
		margin: 20px auto 40px;
	}

	form {
		display: flex;
		flex-direction: column;
	}

	.btn {
		svg {
			width: 16px;
			margin-top: -4px;
		}
	}
}

.standard-layout {
	width: 100%;

	.main-container {
		margin-top: 40px;
	}
}