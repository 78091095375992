.multiple-choice {

}
label {
	display: block;
}
.session-container {
	.form-check {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding-left: 0;
		input {
			margin: 0;
			z-index: -99;
			bottom: 0;
			left: 0;
			opacity: 0;
			margin-left: -18px;
			&:checked + label {
				transition: 300ms ease-out all;
				background: #2684FF;
				color: #fff;
			}
		}
		label {
			padding: 8px 16px;
			background: #f1f1f5;
			border-radius: 8px;
			margin: 0 0 4px;
			transition: 300ms ease-in all;
			font-size: 16px;
			&::first-letter {
				text-transform: capitalize;
			}
			&:hover {
				transition: 300ms ease-out all;
				cursor: pointer;
				background: #C1CED9;
			}
		}
	}
	input[type="text"], textarea {
		font-size: 16px;
		padding: 12px 20px;
	}

}

.horizontal {
	display: flex;
	flex-wrap: wrap;
	@media screen and (max-width: 768px) {
		display: block;
	}
	.form-check {
		label {
			margin-right: 8px;
			@media screen and (max-width: 768px) {
				width: 100%;
			}
		}
	}
}


.form-label {
	font-size: 16px;
	font-weight: 400;
}

.has-error {
	transition: 300ms ease-out all;
	border: 1px solid red;
	padding: 20px;
	border-radius: 12px;
	position: relative;
	box-shadow: 2px 2px 8px rgba(0,0,0,0.3);
	transform: scale(1.05);
	.error {
		height: auto;
		transition: 300ms ease-out all;
	}
}
.error {
	color: red;
	display: block;
	padding: 0px 0;
	margin-top: 0;
	margin-bottom: 12px;
	//height: 0;
	transition: 300ms ease-out all;
}

.form-actions {
	padding:  20px 0;
	display: flex;
	justify-content: space-between;
	// animation-name: panel-enter;
 //  	animation-duration: 300ms;
 //  	animation-iteration-count: 1;
 //  	animation-fill-mode: forwards;
 //  	animation-timing-function: ease-out;
 //  	opacity: 0;
	// .btn-secondary {
	// 	background: transparent;
	// 	font-weight: 400;
	// 	color: #2684FF;
	// 	border: none;
	// 	padding: 0;
	// 	font-size: 16px;
	// 	svg {
	// 		margin-right: 4px;
	// 	}
	// }
}

.form-switch {
	display: flex;
	align-items: center;
	.form-check-input {
		width: 60px;
		height: 30px;
		&:checked {
			background-color: #2684FF;
		}
	}
	.form-check-label {
		margin-left: 12px;
	}
}

.validation-error {
	display: block;
	color: #E86A6A;
	font-size: 14px;
	margin-top: 0px;
	margin-bottom: 12px;
}
.login-form {
	.validation-error {
		margin-top: 8px;
	}
}

.password-meter {
	& > div > div {
		margin-top: 8px !important;
		& > div {
			height: 4px !important;
		}
	}
}

/* The switch - the box around the slider */
.switch {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 34px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
	opacity: 0;
	width: 0;
	height: 0;
  }
  
  /* The slider */
  .slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
  }
  
  .slider:before {
	position: absolute;
	content: "";
	height: 26px;
	width: 26px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
  }
  
  input:checked + .slider {
	background-color: #2684FF;
  }
  
  input:focus + .slider {
	box-shadow: 0 0 1px #2684FF;
  }
  
  input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
	border-radius: 34px;
  }
  
  .slider.round:before {
	border-radius: 50%;
  }