.achievements {
	margin-bottom: 40px;
	h2 {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}
.achievement-card {
	.card {
		border: none;
		border-radius: 8px;
		margin: 0 0 12px;
		box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
		padding: 12px;
	}
	.card-body {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	svg {
		width: 50px;
		height: 50px;
		margin: 0 20px 0 0;
		fill: #72757e;
	}
	h3 {
		margin: -4px 0 0;
		font-size: 18px;
	}
	p {
		margin: 0;
	}
}