.onboarding-welcome,
.onboarding-reflection {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
    text-align: center;
    width: 100%;

    h1 {
        font-size: 48px;
        text-align: center;
    }

    p {
        font-size: 18px;
    }

    .bullet-list {
        margin-top: 40px;
    }

    .bullet-item {
        display: flex;
        justify-content: start;
        align-items: center;
        text-align: left;
        margin-bottom: 40px;

        h3 {
            font-size: 22px;
            margin-bottom: 2px;
        }

        p {
            font-size: 16px;
            margin: 0;
        }
    }

    .icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ddd;
        margin-right: 20px;
        padding: 16px;
        width: 80px;
        height: 80px;
        border-radius: 50%;

        svg {
            width: 60px;
            height: 60px;
        }

    }
}

.onboarding-reflection {

}

.onboarding-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 80px;
    .form-check {
        text-align: left;
        padding-top: 8px;
        padding-bottom: 8px;
    }
    h3 {
        text-align: left;
    }
}

.action-button-container {
    background: #fff;
    position: fixed;
    bottom: 0px;
    left: 0;
    width: 100%;
    padding: 28px 40px 28px;
    border-top: 2px solid #ddd;

    .btn {
        font-size: 20px;
        padding: 10px 20px;
    }
}

.strategy-category {
    border: 2px solid #ddd;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 20px;
    span {
        display: block;
        text-align: left;
        color: #aaa;
    }
}

