#my-connections {
    margin-bottom: 20px;
}
.approved {
    background-color: #dbecd1 !important;
    border-color: #427A5F;
    color: #427A5F !important;
    opacity: 1 !important;
}
.pending {
    background-color: #d6deff !important;
    border-color: #4264E6;
    color: #4264E6 !important;
    opacity: 1 !important;
}
