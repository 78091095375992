.goal-container {
	border: 2px solid #ddd;
	border-radius: 12px;
	padding: 20px;

	h2 {
		font-size: 20px;
	}
	
	.btn-text {
		padding: 0 0 8px;
	}

	.goal-statement {
		font-size: 16px;
		line-height: 1.4;

		h3 {
			font-weight: 600;
			font-size: 16px;
			line-height: 1.4;
		}
	}

	.goal-metadata {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-top: 12px;
	}

	.reviews,
	.duration {
		display: flex;
		align-items: center;
		font-size: 14px;
		margin-right: 24px;

		svg {
			width: 20px;
			height: 20px;
		}
	}

	.create-goal {
		text-decoration: none !important;
		background: transparent;
		box-shadow: none;
		border: 2px dashed #bddbb7;
		text-align: center;
		transition: 200ms ease-out all;

		&:hover {
			background: #fff;
			transition: 200ms ease-out all;
			color: #4264E6;
		}

		.card-body {
			padding: 1rem;
			text-decoration: none !important;
		}

		a {
			color: #427A5F;
			text-decoration: none !important;
		}
	}

	a {
		text-decoration: none;
	}

	.empty-goals {
		text-align: left;
		padding: 20px 0;

		h3 {
			margin-bottom: 12px;
		}
	}

	.dropdown {
		display: flex;
		justify-content: center;
		align-content: center;
		flex-wrap: nowrap;
		flex-direction: row;
		align-items: center;

		.btn {
			margin: 0;
			background: #fff;
			border: 1px solid $nearBlack;
			color: $nearBlack;

			&:first-child {
				border-right: none;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}

			&:nth-child(2) {
				border-top-left-radius: 0 !important;
				border-bottom-left-radius: 0 !important;
			}
		}
	}
}

h4 {
	&:hover {
		cursor: pointer;
	}
	svg {
		width: 16px;
		height: 16px;
	}
}

.user-goal-card {
	.goal-statement-container {
		.goal-statement {
			h3 {
				color: #21252a;
				line-height: 1.4;
				margin-bottom: 8px;
			}
			p {
				color: #21252a;
			}
		}
	}


	.goal-metadata {
		.reviews {
			svg {
				width: 20px;
				height: 20px;
				margin-left: 8px;
				margin-right: 0;
			}
		}
	}
}