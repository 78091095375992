.reflection-activities {
	margin-bottom: 60px;
	.card {
		padding: 20px;
		border-radius: 8px;
		border: none;
	}
}

.profile-container {
	//margin-left: 20px;
}
.profile {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 20px 20px;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
	.avatar-container {
		width: 100px;
		height: 100px;
		border-radius: 50px;
		//background: #dbecd1;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 12px;
	}
	svg {
		width: 60px;
		height: auto;
		fill: #427A5F;
	}
	h2 {
		display: block;
		font-size: 24px;
		font-weight: 700;
		margin-bottom: 0;
		text-align: center;
	}
	h3 {
		text-align: center;
		font-size: 16px;
		font-weight: 400;
		color: #777;
	}
}
.coach-info {
	p:last-child {
		font-size: 14px;
	}
}