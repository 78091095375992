.profile-container {
    h2 {
        font-size: 24px;
    }
    .panel {
        border: 2px solid #ddd;
        border-radius: 12px;
        padding: 32px;
        svg {
            width: 42px;
            height: 42px;
            margin-right: 20px;
        }
        h3 {
            margin-bottom: 0;
            line-height: 1.4;
            font-size: 18px;
        }
        span {
            display: block;
            font-size: 14px;
            line-height: 1.4;
            color: #666;
        }
    }
}
.coach-container .panel{
    svg {
        width: 20px;
        height: 20px;
        margin: 0;
    }
}