.btn {
	margin: -2px 0 0;
	box-shadow: 0 4px 0px #aaa;
	transition: 0ms ease all;
	&:active {
		box-shadow: none;
		transform: translateY(4px);
		transition: 0ms ease all;
	}



	&.btn-primary {
		background-color: #2684FF;
		border-color: #2684FF;
		box-shadow: 0 4px 0px #1F6FD9;

		&:hover {
			background-color: #2684FF;
			border-color: #2684FF;
		}

		&:active {
			background-color: #2684FF;
			border-color: #2684FF;
			box-shadow: none;
		}
	}


	&.btn-secondary {
		background: #f1f1f1;
		border: 2px solid #f1f1f1;
		color: #212529;
		box-shadow: 0 4px 0px #ddd;
		&:active {
			background-color: #f1f1f1;
			border-color: #f1f1f1;
			box-shadow: none;
			color: #212529;
		}

		&:disabled {
			box-shadow: none;
			opacity: 1;
		}
		svg {
			margin-right: 8px;
		}
	}

	&.btn-danger {
		// background: #f1f1f1;
		// border: 2px solid #f1f1f1;
		// color: #212529;
		// box-shadow: 0 4px 0px #ddd;
		// margin-right: 8px;
		// &:active {
		// 	background-color: #f1f1f1;
		// 	border-color: #f1f1f1;
		// 	box-shadow: none;
		// 	color: #212529;
		// }

		// &:disabled {
		// 	box-shadow: none;
		// 	opacity: 1;
		// }
		// svg {
		// 	margin-right: 8px;
		// }
		box-shadow: 0 4px 0px #b81e2b;
	}

	&.btn-outline-primary {
		border: 1px solid #4264E6;
		color: #4264E6;

		&:hover {
			background: #4264E6;
			color: #fff;
			border: 1px solid #4264E6;
		}
	}

	&.btn-text {
		display: flex;
		align-items: center;
		background-color: transparent !important;
		border: none;
		padding: 8px 0;
		color: #4264E6;
		text-decoration: none;
		box-shadow: none;
		&:hover,
		&:focus,
		&:active {
			color: #4264E6;
			background-color: transparent !important;
			text-decoration: underline;
			box-shadow: none;
			cursor: pointer;
		}

		svg {
			margin-right: 4px;
		}
	}
}